import React from 'react';
import SparsePostBlock from '../posts/SparsePostBlock';
import usePosts from '../posts/usePosts';
import DefaultView from './DefaultView';
import { EVENTS_TYPE } from '../Constants';
import { useParams } from 'react-router';
import EventsSchedule from '../events/EventsSchedule';

const ROOT_CATEGORIES = {
  kalkhoff: 'strategy',
};

/**
 * @typedef {object} ViewProps
 * @property {import('../Kalkhoff').Page} page ...
 * @property {boolean} offline `true` if app is offline
 */

/**
 * @param {{
 *   page: import('../Kalkhoff').Page,
 *   offline: boolean,
 *   children?: JSX.Element | JSX.Element[],
 *   CSSclass?: string,
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export function JSXFeed({ children, page, offline, CSSclass }) {
  return <DefaultView page={ page } offline={ offline }>
    <nav className={ CSSclass }>
      { children }
    </nav>
  </DefaultView>;
}

/**
 * Return feed of general posts type
 *
 * @param {ViewProps} props React props
 * @returns {JSX.Element} ...
 */
function PostsFeedView(props) {
  const [ posts ] = usePosts();
  /** @type {{ topic: string, slug: string }} */
  const { topic, slug } = useParams();
  const category = topic ? slug : ROOT_CATEGORIES[slug];
  const filteredPosts = posts.filter((post) =>
    post._embedded['wp:term'][0][0].slug === category);

  return <JSXFeed
    { ...props }
    CSSclass="posts-list"
  >
    { filteredPosts.map((post) => (
      <SparsePostBlock key={ post.id } post={ post }/>
    )) }
  </JSXFeed>;
}

/**
 * @param {{
 *   page: import('../Kalkhoff').Page,
 *   offline: boolean,
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function FeedView({ page, offline }) {
  if (page.slug === EVENTS_TYPE) {
    return <EventsSchedule page={ page } offline={ offline }/>;
  }
  return <PostsFeedView page={ page } offline={ offline }/>;
}
