import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FORMS_ID, FORMS_ID as FORMS_ID_EN, FORMS_ID_DE } from '../Constants';
import { fetchForm } from '../dao/forms';
import DefaultView from '../pages/DefaultView';
import usePage from '../pages/usePage';
import FormFactory from './FormFactory';

const FORM_IDS = {
  'en': FORMS_ID_EN,
  'de': FORMS_ID_DE,
  'default': FORMS_ID_DE,
};

/**
 * @param {string} locale locale to fetch form for
 *
 * @returns {string} Localized form id
 */
function getFormsID(locale) {
  return FORM_IDS[locale] || FORMS_ID.default;
}

/**
 * @returns {JSX.Element|null} ...
 */
export default function FormView() {
  const { page } = usePage('feedback');
  const [ formData, setFormData ] = useState({});
  const { i18n } = useTranslation();

  useEffect(() => {
    fetchForm(getFormsID(i18n.language)).then((result) => {
      setFormData(result);
    });
  }, [ i18n.language ]);

  return <DefaultView page={ page || { title: '' } }>
    <FormFactory formData={ formData } />
  </DefaultView>;
}
