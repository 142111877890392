import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Icon from '../icons/Icon';
import { BasePostView } from '../posts/PostView';
import { useEvent } from './useEvents';
import useInterest from './useInterest';

/**
 * @returns {JSX.Element} React component
 */
export default function EventView() {
  /**
   * @type {object}
   * @property {string} slug Unique identifier
   */
  const { slug } = useParams();
  /** @type {import('../Kalkhoff').Event | import('../Kalkhoff').SparseEvent} */
  const event = useEvent(slug);
  const [ isInterested, setInterested ] = useInterest(event);
  const { t } = useTranslation();

  /**
   * @param {import('react').SyntheticEvent} e Actual mouse-event
   */
  function clickHandler(e) {
    e.stopPropagation();
    e.preventDefault();
    setInterested(!isInterested);
  }

  return <BasePostView
    post={ event }
    likeButton={ (<button
      onClick={ clickHandler }
      className={ `event-view__interested-cta${
        isInterested ? '--interested' : ''
      }` }
    >
      <Icon
        icon={ `icon-${isInterested ? 'checkmark' : 'star'}` }
      />{ t('interested') }
    </button>) }
  />;
}
