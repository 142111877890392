import React from 'react';
import { Trans } from 'react-i18next';

/**
 * @param {{
 *   pageClass?: string,
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export function Back({ pageClass }) {
  /**
   * @param {{
   *   preventDefault: Function,
   * }} e mouse-event from DOM...
   * @returns {boolean} false
   */
  function goBack(e) {
    e.preventDefault();
    window.history.go(-1);
    return false;
  }

  return (
    <button onClick={ goBack } className={ `${pageClass}__back` }>
      <Trans i18nKey="back">
        <em>Back</em>
      </Trans>
    </button>
  );
}
