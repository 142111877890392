import { useEffect, useState } from 'react';
import { fetchNavigationByLocation } from '../dao/navigation';
import { useOktaAuth } from '@okta/okta-react';

/**
 * Basic hook to fetch navigation from the server.
 *
 * @param {string} location Location for the post
 *
 * @returns {Array<import('../Kalkhoff').Navitem>}
 *          returns the `navigation` state.
 */
export function useNavigation(location) {
  const { authState } = useOktaAuth();
  const [ navigation, setNavigation ] = useState([]);

  useEffect(() => {
    if (location && authState?.isAuthenticated) {
      fetchNavigationByLocation(location)
          .then((navigation) => setNavigation(navigation))
      ;
    }
  }, [ location, authState?.isAuthenticated ]);

  return navigation;
}
