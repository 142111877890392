import React from 'react';
import { useHistory, useParams } from 'react-router';
import InProgress from '../helpers/InProgress';
import { refactorUrl } from '../helpers/url';

/**
 * @param {{
 *  offline?: boolean
 * }} props React props
 * @returns {JSX.Element|null} ...
 */
export default function EventRouter(props) {
  const history = useHistory();

  /**
   * @type {object}
   * @property {string} slug Unique identifier
   */
  const { slug } = useParams();

  history.replace(refactorUrl(`/events/${slug}`));

  // Meanwhile, show a spinner (never seen it, navitems are in too fast)
  return <InProgress inProgress={ true } />;
}
