import { FORMS_API } from '../Constants';
import { addOktaBearerToFetchOptions } from './api';

/**
 * @param {string} formId Identifier for the form we're fetching
 * @returns {Promise<Object<string, any>>} Resolves to the JSON of the response
 */
export async function fetchForm(formId) {
  const requestData = {
    url: `${FORMS_API}/${formId}`,
    method: 'GET',
    data: '',
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const authOptions = await addOktaBearerToFetchOptions(options);
  const response = await fetch(requestData.url, {
    method: requestData.method,
    ...authOptions,
  });

  return response.json();
}

/**
 * @param {Object<string, any>} formContext The form-field name/value pairs
 * @returns {FormData} flattened context
 */
function contextToFormData(formContext) {
  return Object.keys(formContext).reduce(
      (formData, fieldID) => {
        formData.append(`input_${fieldID}`, formContext[fieldID].value);
        return formData;
      },
      new FormData(),
  );
}

/**
 * @param {string} formId Identifier for the form we're fetching
 * @param {Object<string, any>} formContext The form-field name/value pairs
 * @returns {Promise<Object<string, any>>} Resolves to the JSON of the response
 */
export async function postForm(formId, formContext) {
  const requestOptions = {
    method: 'POST',
    body: contextToFormData(formContext),
  };
  const options = await addOktaBearerToFetchOptions(requestOptions);

  const response = await fetch(
      `${FORMS_API}/${formId}/submissions`,
      options,
  );

  return response.json();
}
