/* eslint-disable max-len */
const de = {
  translation: {
    greeting: 'Hallo, <1>{{name}}</1>',
    reply: 'Antworten',
    interested: 'Interessiert',
    notifications: 'Benachrichtigungen',
    back: 'Zurück',
    inProgress: 'im Gange',
    toggleNotification: 'Ich möchte Benachrichtigungen auf meinem Telefon erhalten',
    formDesc: 'Wenn du das App-Team kontaktieren möchtest, ' +
      'kannst du ganz unkompliziert das unten anstehende Formular nutzen. ' +
      'Wir freuen uns über deine Nachricht - ganz gleich, ' +
      'ob du Fragen hast oder uns dein Feedback übermitteln möchtest.',
    incidentDesc: 'Um einen Unfall, ein Risiko oder einen beinahe ' +
      'Unfall zu melden, folge diesem Link zu unserem <1>Unfallformular<1>.',
    phoneTitle: 'Mobiltelefonnummer',
    phoneDesc: 'Mobiltelefonnumer inklusive Länder-Vorwahl (z.B.: +491...)',
    submit: 'Submit',
    allPosts: 'alle Artikel',
    language: 'Sprache',
    english: 'Englisch',
    german: 'Deutsch',
    noPosts: 'Es sind derzeit keine Beiträge verfügbar.',
    upload: 'Bild hochladen',
    loadMore: 'Mehr laden',
    allHrNews: 'Alle HR-News anzeigen',
    searchPosts: 'Artikel suchen',
    notFound: 'Die gesuchte Seite existiert nicht (mehr).',
    backToStart: 'Gehen Sie zurück zum Startbildschirm.',
    invalidForm: 'Das folgende Feld ist erforderlich: <1>{{invalidInput}}</1>',
    notificationsOn: 'Nachrichten aktiviert',
    notificationsOff: 'Nachrichten deaktiviert',
  },
};

export default de;
