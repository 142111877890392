import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  HR_CATEGORY_ID_DE,
  HR_CATEGORY_ID_EN,
  HR_POST_COUNT,
} from '../Constants';
import { urlParser } from '../helpers/url';
import SparsePostBlock from '../posts/SparsePostBlock';
import usePosts from '../posts/usePosts';
import DefaultView from './DefaultView';
import TeamOverview from '../team/TeamOverview';

const BASE_CLASS = 'hr-view';
const HR_CATEGORY_IDS = {
  'en': HR_CATEGORY_ID_EN,
  'de': HR_CATEGORY_ID_DE,
  'default': HR_CATEGORY_ID_DE,
};

/**
 * @param {string} locale locale to fetch posts for
 *
 * @returns {number} The HR category ID
 */
function getHRCategoryID(locale) {
  return HR_CATEGORY_IDS[locale] || HR_CATEGORY_IDS.default;
}

/**
 * @param {{
 *   page: import('../Kalkhoff').Page,
 *   className?: string,
 *   offline?: boolean,
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function HRView({ page, offline, className }) {
  const baseClass = className || BASE_CLASS;
  const { i18n } = useTranslation();
  const language = i18n.language;

  // Get posts with category HR.
  const HRPosts = usePosts(
      getHRCategoryID(language),
      HR_POST_COUNT,
      [],
  )[0];

  return <DefaultView page={ page } offline={ offline }>
    <article dangerouslySetInnerHTML={ {
      __html: urlParser(page.content?.rendered),
    } }
    className={ `${baseClass}__content` }
    />
    <TeamOverview/>
    { HRPosts && 0 !== HRPosts.length &&
      <>
        <div className={ `${baseClass}__posts` }>
          { HRPosts.map((HRPost) => (
            <SparsePostBlock key={ HRPost.id } post={ HRPost }/>
          )) }
        </div>
        <div className={ `${baseClass}__button-wrapper` }>
          <Link
            to={ `/${ language }/?category=${ getHRCategoryID(language) }` }
          >
            <Trans i18nKey="allHrNews">Show all HR news</Trans>
          </Link>
        </div>
      </>
    }
  </DefaultView>;
}
