import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import ContactView from './ContactView';
import DefaultView from './DefaultView';
import OfflineView from './OfflineView';
import ChartView from './ChartView';
import FormView from './FormView';
import FeedView from './FeedView';
import usePage, { usePreviewPage } from './usePage';
import { formatPreviewData } from '../helpers/Preview';
import HRView from './HRView';

const VIEW_TYPES = {
  'feed': FeedView,
  'contact': ContactView,
  'default': DefaultView,
  'offline': OfflineView,
  'chart': ChartView,
  'form': FormView,
  'hr': HRView,
};

/**
 * @param {{
 *  offline?: boolean
 * }} arg React props
 * @returns {JSX.Element|null} ...
 */
export default function PageView({ offline }) {
  /**
   * @type {object}
   * @property {string} slug Unique identifier
   */
  const { slug, topic, subject } = useParams();
  const history = useHistory();

  // Check if there is a preview_id.
  const previewId = new URLSearchParams(useLocation()?.search)
      .get('preview_id');

  // Use react-hook conditionally to prevent unnecessary API calls.
  // eslint-disable-next-line react-hooks/rules-of-hooks, max-len
  let { page, dataLoading } = previewId ? usePreviewPage(previewId) : usePage(slug, topic, subject);

  if (previewId && page) {
    page = formatPreviewData(page, 'page');
  }

  if (page === null) {
    if (dataLoading) {
      return null;
    } else {
      history.push('/404');
      return null;
    }
  }

  const ViewType = VIEW_TYPES[page.template] || VIEW_TYPES['default'];

  return <ViewType page={ page } offline={ offline }/>;
}
