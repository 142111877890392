import i18n from 'i18next';
import BrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './i18n/en';
import de from './i18n/de';
import DetectKnowLocaleFromURL from './i18n/DetectKnownLocaleFromURL';

const browserLanguageDetector = new BrowserLanguageDetector();
browserLanguageDetector.addDetector(DetectKnowLocaleFromURL);

const resources = {
  en,
  de,
};

export const locales = Object.keys(resources);

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(browserLanguageDetector)
    .init({
      resources,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      detection: {
        order: [
          DetectKnowLocaleFromURL.name,
          'querystring',
          'cookie',
          'localStorage',
          'sessionStorage',
          'navigator',
          'htmlTag',
          'subdomain',
        ],
        knownLocales: locales,
      },
    })
;

export default i18n;
