import React from 'react';
import { RELATED_POST_COUNT } from '../Constants';
import SparsePostBlock from './SparsePostBlock';
import usePosts from './usePosts';

/**
 * @param {{
 *   post: import('../Kalkhoff').Post | import('../Kalkhoff').SparsePost,
 * }} arg React props
 * @returns {JSX.Element} React component
 */
export default function RelatedPosts({ post }) {
  // Get 3 related posts based on the category of the current post.
  // Since we get an array of arrays we want the first item.
  const relatedPosts = usePosts(
      String(post?.categories[0]),
      RELATED_POST_COUNT,
      [ String(post?.id) ],
  )[0];

  return <div className='related-posts'>
    { relatedPosts && relatedPosts.map((relatedPost, i) => (
      // Show a maximum items.
      i < RELATED_POST_COUNT &&
        <SparsePostBlock key={ relatedPost.id } post={ relatedPost }/>
    )) }
  </div>;
}
