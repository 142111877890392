/** @typedef {import('../Kalkhoff').SparsePost} SparsePost */
/** @typedef {import('../Kalkhoff').Post} Post */
import { useEffect, useState } from 'react';
import { DEFAULT_POST_COUNT } from '../Constants';
import { fetchPostBySlug, fetchPosts } from '../dao/posts';
import { fetchPreviewById } from '../dao/preview';

// Create a default excludeIds array to prevent infinite loop
const defaultExcludeIds = [];

/**
 * Basic hook to fetch posts from the server.
 *
 * @param {string} filter - Category to filter on.
 * @param {number} amount - Amount of posts.
 * @param {Array} excludeIds - Post IDs to exclude
 * @param {string} search - Search query.
 * @param {boolean} sticky - Sticky posts.
 *
 * @returns {Array<SparsePost[]>} returns the `posts` state.
 */
export default function usePosts(
    filter,
    amount = DEFAULT_POST_COUNT,
    excludeIds = defaultExcludeIds,
    search = '',
    sticky = false,
) {
  const [ posts, setPosts ] = useState(null);

  useEffect(() => {
    fetchPosts(filter, amount, excludeIds, search, sticky)
        .then((result) => setPosts(result))
        .catch((error) => {
          console.error('Failed to load posts', error);
          setPosts([]);
        })
    ;
  // Only change on filter to prevent infinite loop on post detail page.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ filter ]);

  return [ posts ];
}

/**
 * @param {string} slug Unique slug for the post
 * @returns {Object} The post and loading status
 */
export function usePost(slug) {
  const [ post, setPost ] = useState(null);
  const [ dataLoading, setDataLoading ] = useState(true);

  useEffect(() => {
    fetchPostBySlug(slug)
        .then((post) => setPost(post || null))
        .then(() => setDataLoading(false))
    ;
  }, [ slug ]);

  return { post, dataLoading };
}

/**
 * @param {string} previewId Unique previewId
 * @returns {Object} The post and loading status
 */
export function usePreviewPost(previewId) {
  const [ post, setPost ] = useState(null);
  const [ dataLoading, setDataLoading ] = useState(true);

  useEffect(() => {
    fetchPreviewById(previewId)
        // Use the most recent revision
        .then((result) => setPost(Object.values(result).slice(-1)[0] || null))
        .then(() => setDataLoading(false))
    ;
  }, [ previewId ]);

  return { post, dataLoading };
}
