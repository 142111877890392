import { TEAM_API } from '../Constants';
import { fetchJSONFromBackend } from './api';

/**
 * Fetch the team members by category.
 *
 * @returns {Promise<Array>} Resolves to array of team members.
 */
export async function fetchTeamMembers() {
  return fetchJSONFromBackend(
      TEAM_API,
      '/team',
  ).then((res) => {
    return res;
  });
}
