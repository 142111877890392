import { useEffect, useState } from 'react';
import { fetchPageBySlug } from '../dao/pages';
import { fetchPreviewById } from '../dao/preview';

/**
 * @param {string} slug Unique slug for the page
 * @param {string} topic Unique topic for the page
 * @param {string} subject Unique subject for the page
 * @returns {Object} The page and loading status
 */
export default function usePage(slug, topic, subject) {
  const [ page, setPage ] = useState(null);
  const [ dataLoading, setDataLoading ] = useState(true);

  useEffect(() => {
    fetchPageBySlug(slug, topic, subject)
        .then((page) => setPage(page || null))
        .then(() => setDataLoading(false))
    ;
  }, [ slug, topic, subject ]);

  return { page, dataLoading };
}

/**
 * @param {string} previewId Unique previewId
 * @returns {Object} The page and loading status
 */
export function usePreviewPage(previewId) {
  const [ page, setPage ] = useState(null);
  const [ dataLoading, setDataLoading ] = useState(true);

  useEffect(() => {
    fetchPreviewById(previewId)
        // Use the most recent revision
        .then((result) => setPage(Object.values(result).slice(-1)[0] || null))
        .then(() => setDataLoading(false))
    ;
  }, [ previewId ]);

  return { page, dataLoading };
}
