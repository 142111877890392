import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import BlockViewFactory from './BlockViewFactory';
import usePosts from './usePosts';
import InProgress from '../helpers/InProgress';
import {
  DEFAULT_POST_COUNT,
  INITIAL_POST_COUNT,
  EVENTS_POST_TYPE,
} from '../Constants';
import { fetchPosts } from '../dao/posts';
import { getTotalPages } from '../dao/misc';
import { Trans } from 'react-i18next';

const POSTS_ENDPOINT = '/posts';
let pageNumber = 1;
// By default nextPage is true, to trigger the fetchPosts after initial load.
let nextPage = true;

/**
 * @param {{
 *   filter: string,
 *   search: string,
 * }} arg React props
 *
 * @returns {JSX.Element} The goods
 */
export default function PostsList({ filter, search }) {
  const [ loading, setLoading ] = useState(true);
  const [ posts ] = usePosts(filter, INITIAL_POST_COUNT, [], search, true);
  const [ allPosts, setAllPosts ] = useState([]);
  const [ initialPostsFinished, setInitialPostsFinished ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ totalPages, setTotalPages ] = useState(1);
  const isSearching = useRef(new URLSearchParams(useLocation()?.search)
      .get('search') ?? '');
  const isCategory = useRef(new URLSearchParams(useLocation()?.search)
      .get('category') ?? '');

  useEffect(() => {
    if (posts) {
      setAllPosts(posts);
      setInitialPostsFinished(true);

      const fetchTotalPages = async () => {
        setTotalPages(
            await getTotalPages(POSTS_ENDPOINT, {
              pon_extra_post_type: EVENTS_POST_TYPE,
              per_page: DEFAULT_POST_COUNT,
              categories: filter,
              search: search,
            }),
        );
      };
      fetchTotalPages();
    }
  }, [ posts, filter, search ]);

  useEffect(() => {
    if (initialPostsFinished) {
      const excludeIds = [];
      if (!search && pageNumber === 1) {
        posts.forEach((post) => {
          excludeIds.push(String(post.id));
        });
      }

      let amount = DEFAULT_POST_COUNT;
      if (pageNumber === 1) {
        amount = DEFAULT_POST_COUNT - posts.length;
      }

      if (
        (isSearching.current !== search || isCategory.current !== filter) &&
        ! nextPage // Do not check when next button is pressed
      ) {
        pageNumber = 1;
      }

      fetchPosts(
          filter,
          amount,
          excludeIds,
          search,
          false,
          pageNumber,
      ).then((result) => {
        // Don't include previous posts if search function is used.
        if (
          (isSearching.current !== search || isCategory.current !== filter) &&
          ! nextPage // Do not check when next button is pressed
        ) {
          setAllPosts(result);
          isSearching.current = search;
          isCategory.current = filter;
        } else {
          setAllPosts((prevState) => {
            return [ ...prevState, ...result ]
                // Remove duplicate posts.
                .reduce((unique, o) => {
                  if (!unique.some((obj) => obj.id === o.id)) {
                    unique.push(o);
                  }
                  return unique;
                }, []);
          });
        }

        // Reset the next page check
        nextPage = false;
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [ posts, filter, search, initialPostsFinished, page ]);

  useEffect(() => {
    // Set loading to true when filter changes.
    setLoading(true);
  }, [ filter, search ]);

  if (loading) {
    return <InProgress inProgress={ true } />;
  }

  if (!allPosts?.length && !loading) {
    return <p>
      <Trans i18nKey="noPosts">
          There are currently no posts available.
      </Trans>
    </p>;
  }

  return (
    <>
      <nav className="posts-list">
        { allPosts?.map((post) => (
          <BlockViewFactory key={ post.id } post={ post } />
        )) }
      </nav>
      { pageNumber < totalPages &&
        <div className="posts-list__pagination">
          <button
            onClick={ () => {
              setPage(page + 1);
              pageNumber = pageNumber + 1;

              // Set nextPage to true, so we know that we want to keep
              // the prev state later on
              nextPage = true;
            } }
          >
            <Trans i18nKey="loadMore">Load more</Trans>
          </button>
        </div>
      }
    </>
  );
}
