import React, { useEffect, useState } from 'react';
import { fetchTeamMembers } from '../dao/team';

/**
 * Team overview component.
 *
 * @returns {JSX.Element} ...
 */
export default function TeamOverview() {
  const [ teamMembers, setTeamMembers ] = useState([]);

  useEffect(() => {
    getTeamMembers();
  }, []);

  /**
   * Get team members.
   */
  async function getTeamMembers() {
    await fetchTeamMembers().then((res) => {
      setTeamMembers(res);
    });
  }

  return (
    <div className="team-overview">
      { teamMembers.map((teamMemberCategory) => (
        <div key={ teamMemberCategory.categoryID }>
          <h2 className="team-overview__title">
            { teamMemberCategory.categoryName }
          </h2>
          <div className="team-overview__container">
            { teamMemberCategory.posts.map((teamMember) => (
              <div key={ teamMember.id } className="team-member">
                <img
                  loading='lazy'
                  src={ teamMember.image }
                  alt={ `${teamMember.name}` }
                />
                <p>{ teamMember.name }</p>
                <p>{ teamMember.function }</p>
                <a href={ `mailto:${teamMember.email}` }>
                  { teamMember.email }
                </a>
              </div>
            )) }
          </div>
        </div>
      )) }
    </div>
  );
}
