import { useEffect, useState } from 'react';
import { fetchCategories } from '../dao/categories';

/**
 * Basic hook to fetch categories from the server.
 *
 * @returns {Array} returns the `categories` state.
 */
export default function useCategories() {
  const [ categories, setCategories ] = useState([]);

  useEffect(() => {
    fetchCategories()
        .then((categories) => setCategories(categories))
        .catch((error) => {
          console.error('Failed to load categories', error);
          setCategories([]);
        })
    ;
  }, []);

  return [ categories ];
}
