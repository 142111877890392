import { LANGUAGE_API } from '../Constants';
import { fetchJSONFromBackend, postJSONToBackend } from './api';

const API_ENDPOINT_LANGUAGE = '/language/email';

/**
 * @param {string} id UID for user to get status on (email)
 * @returns {Promise<object>} Resolves to status
 */
export async function fetchUserLanguage(id) {
  const url = `${API_ENDPOINT_LANGUAGE}/${id}`;
  return fetchJSONFromBackend(
      LANGUAGE_API,
      url,
  ).then(async (result) => {
    const language = result?.language;
    return {
      'language': language,
    };
  });
}

/**
 * @param {string} id UID for user to get status on (email)
 * @param {string} lang The new user language
 * @returns {Promise<boolean>} Resolves to new status
 */
export async function changeUserLanguage(id, lang) {
  const url = `${API_ENDPOINT_LANGUAGE}/${id}`;
  return postJSONToBackend(
      LANGUAGE_API,
      url,
      {
        'language': lang,
      },
  ).then((result) => {
    return result;
  });
}
