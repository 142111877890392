import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { DEBOUNCE_DELAY } from '../Constants';
import useDebounce from '../helpers/useDebounce';

/**
 * @param {{
 *   setSearch: Function,
 * }} arg React props.
 *
 * @returns {JSX.Element} The goods
 */
export default function PostsSearch({ setSearch }) {
  const [ searchTerm, setSearchTerm ] = useState(
      new URLSearchParams(useLocation()?.search).get('search') ?? '',
  );
  // Debounced function
  const debouncedValue = useDebounce(searchTerm, DEBOUNCE_DELAY);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setSearch(debouncedValue);
  }, [ debouncedValue, setSearch ]);

  /**
   * Update the search params.
   *
   * @param {{
   *  target: {
   *    value: string,
   *  }
   * }} event Change event
   */
  function updateSearchParams(event) {
    const queryParams =
      new URLSearchParams(location?.search).get('category') ?? '';
    history.push({
      search: `?search=${event.target.value}${
        queryParams ? `&category=${queryParams}` : ''
      }`,
    });
  }

  return <input
    className='posts-search'
    type='text'
    placeholder={ t('searchPosts') }
    value={ searchTerm }
    onChange={ (event) => {
      setSearchTerm(event.target.value);
      updateSearchParams(event);
    } }
  />;
}
