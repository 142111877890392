import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useFormContext } from './FormFactory';

/**
 * @param {{
 *  field: Object<string, any>
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function FileUpload({ field }) {
  const { formContext, setFormContext } = useFormContext();
  const [ image, setImage ] = useState(null);
  const filepickerRef = useRef();

  const uploadimage = (e) => {
    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);

      setFormContext({
        ...formContext,
        [field.id]: {
          label: 'File',
          value: e.target.files[0],
        },
      });
    }
    reader.onload = (readerEvent) => {
      setImage(readerEvent.target.result);
    };
  };

  return <div className="fileupload-field">
    { !image && (
      <div
        className="fileupload-field__placeholder"
        onClick={ () => filepickerRef.current.click() }
      >
        <span>
          <Trans i18nKey="upload">
            Upload
          </Trans>
        </span>
      </div>
    ) }
    { image && (
      <img
        loading="lazy"
        className="fileupload-field__content"
        src={ image }
        alt="img"
        onClick={ () => filepickerRef.current.click() }
      />
    ) }
    <input
      hidden
      onChange={ uploadimage }
      ref={ filepickerRef }
      type="file"
      accept="image/png, image/gif, image/jpeg"
    />
  </div>;
}
