import { CORE_API, META_API, NAV_API } from '../Constants';
import i18n from '../i18n';
import { oktaAuth } from '../App';

/**
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @returns {URLSearchParams} The input as search params
 */
function convertJSONToSearchParams(parameters = {}) {
  const lang = i18n.language;
  const params = {
    lang, // Set lang first, allows override from callee
    ...parameters,
  };
  return Object.keys(params).reduce(
      function convertToParam(acc, key) {
        acc.append(key, params[key]);
        return acc;
      },
      new URLSearchParams(),
  );
}

/**
 * @param {Object<string, string>} [options] `Fetch` options Object
 *
 * @returns {Promise<Object<string, string>>} Resolves to options
 */
export async function addOktaBearerToFetchOptions(options) {
  const allOptions = { ...options };
  const token = await oktaAuth.getAccessToken();
  const headerValue = `Bearer ${token}`;
  const headerName = 'Authorization';

  // Other headers might already exist
  if (allOptions.headers) {
    // headers can be `Headers`
    if (allOptions.headers.set) {
      allOptions.headers.set(headerName, headerValue);
    } else { // or simple JSON
      allOptions.headers[headerName] = headerValue;
    }
  } else { // Just add simple JSON instead
    allOptions.headers = {
      [headerName]: headerValue,
    };
  }

  // Original object is modified, but returning to be polite
  return allOptions;
}

/**
 * @param {string} apiRoot The root of the API
 * @param {string} endPoint The api end-point to target
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object (NYI)
 * @returns {Promise<any>} Resolves to the response
 */
async function fetchFromBackend(apiRoot, endPoint, parameters, options) {
  const params = convertJSONToSearchParams(parameters);
  const authOptions = await addOktaBearerToFetchOptions(options);
  return fetch(`${apiRoot}${endPoint}?${params.toString()}`, {
    method: 'GET',
    ...authOptions,
  });
}

/**
 * @param {string} endPoint The api end-point to target
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @returns {Promise<Response>} Resolves to the response
 */
export function fetchFromAPI(endPoint, parameters, options) {
  return fetchFromBackend(CORE_API, endPoint, parameters, options);
}

/**
 * @param {string} apiRoot The root of the API
 * @param {string} endPoint The api end-point to target
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @returns {Promise<any>} Resolves to the JSON of the response
 */
export function fetchJSONFromBackend(apiRoot, endPoint, parameters, options) {
  return fetchFromBackend(apiRoot, endPoint, parameters, options)
      .then((response) => response.json())
  ;
}

/**
 * @param {string} apiRoot The root of the API
 * @param {string} endPoint The api end-point to target
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @returns {Promise<any>} Resolves to the JSON of the response
 */
export function fetchTotalPages(apiRoot, endPoint, parameters, options) {
  return fetchFromBackend(apiRoot, endPoint, parameters, options)
      .then((response) => response.headers.get('X-WP-TotalPages'))
  ;
}

/**
 * @param {string} root The root of the api
 * @param {string} endPoint The api end-point to target
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @returns {Promise<any>} Resolves to the JSON of the response
 */
export function fetchJSONFromAPI(root, endPoint, parameters, options) {
  return fetchJSONFromBackend(root, endPoint, parameters, options);
}

/**
 * @param {string} endPoint The api end-point to target
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @returns {Promise<any>} Resolves to the JSON of the response
 */
export function fetchJSONFromMeta(endPoint, parameters, options) {
  return fetchJSONFromBackend(META_API, endPoint, parameters, options);
}

/**
 * @param {string} endPoint The api end-point to target
 * @param {string} location location to add to the request
 * @returns {Promise<any>} Resolves to the JSON of the response
 */
export async function fetchNavFromAPI(endPoint, location) {
  const apiURL = `${NAV_API}${endPoint}/${location}`;
  const authOptions = await addOktaBearerToFetchOptions();
  return fetch(apiURL, { ...authOptions })
      .then((response) => {
        if (response.status >= 400) {
          throw (response);
        }
        return response;
      })
      .then((response) => response.json())
      .catch((error) => {
        console.error('Error during navigation fetch:', error);
      });
}

/**
 * @param {string} apiRoot The root of the API
 * @param {string} endPoint The api end-point to target
 * @param {object} data Object that can be parsed by `JSON.parse`
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @returns {Promise<any>} Resolves to the JSON of the response
 */
export async function postJSONToBackend(
    apiRoot,
    endPoint,
    data = {},
    parameters = {},
    options = {},
) {
  const params = convertJSONToSearchParams(parameters);
  const allOptions = {
    method: 'POST',
    headers: {},
    body: JSON.stringify(data),
    ...options,
  };

  if (!allOptions.headers['Content-Type']) {
    allOptions.headers['Content-Type'] = 'application/json';
  }

  const fetchOptions = await addOktaBearerToFetchOptions(allOptions);

  const response = await fetch(
      `${apiRoot}${endPoint}?${params.toString()}`,
      { ...fetchOptions },
  );
  return response.json();
}

/**
 * @param {string} endPoint The api end-point to target
 * @param {object} data Object that can be parsed by `JSON.parse`
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @returns {Promise<any>} Resolves to the JSON of the response
 */
export async function postJSONToAPI(endPoint, data, parameters = {}, options) {
  return postJSONToBackend(CORE_API, endPoint, data, parameters, options);
}

/**
 * @param {string} endPoint The api end-point to target
 * @param {object} data Object that can be parsed by `JSON.parse`
 * @param {Object<string, string>} [parameters] Parameters to add to the request
 * @param {Object<string, string>} [options] `Fetch` options Object
 * @returns {Promise<any>} Resolves to the JSON of the response
 */
export async function postJSONToMeta(endPoint, data, parameters = {}, options) {
  return postJSONToBackend(META_API, endPoint, data, parameters, options);
}
