import React, { useEffect, useState } from 'react';

// @ts-ignore
const NotificationsContext = React.createContext();

/**
 * @param {{
 *  children: JSX.Element | JSX.Element[]
 * }} props React props
 *
 * @returns {JSX.Element} The notifications context.
 */
export const NotificationsProvider = ({ children }) => {
  const [ status, setStatus ] = useState(false);
  const [ inProgress, setInprogress ] = useState(true);

  useEffect(() => {
    /**
     * Define the onPAReady function.
     *
     * This function will check whether the users device...
     * is already subscribed to PushAlert notifications.
     */
    const onPAReady = () => {
      setInprogress(true);

      // @ts-ignore
      // eslint-disable-next-line no-undef
      const userInfo = PushAlertCo.getSubsInfo();
      setStatus(userInfo?.status || false);

      setInprogress(false);
    };

    // @ts-ignore
    // Initialize pushalertbyiw and set the onReady event handler
    (window.pushalertbyiw = window.pushalertbyiw || []).push([
      'onReady',
      onPAReady,
    ]);
  }, []);

  useEffect(() => {
    /**
     * Define the onPASuccess function.
     *
     * This function will add a user to their segments...
     * after the user allowed notifications.
     *
     * @param {object} result PushAlert callback data.
     */
    const onPASuccess = (result) => {
      setInprogress(true);

      // False means user just subscribed.
      if (!result.alreadySubscribed) {
        setStatus(true);
      }

      setInprogress(false);
    };

    // @ts-ignore
    // Initialize pushalertbyiw and set the onSuccess event handler
    (window.pushalertbyiw = window.pushalertbyiw || []).push([
      'onSuccess',
      onPASuccess,
    ]);
  }, []);

  /**
   * @returns {Promise} Resolves when status toggle is finished
   */
  async function toggleStatus() {
    setInprogress(true);

    // User is subscribed and wants to unsubscribe.
    if (status === true) {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      PushAlertCo.unsubscribe();
    } else {
      // User isn't subscribed and wants to subscribe.
      // @ts-ignore
      // eslint-disable-next-line no-undef
      PushAlertCo.forceSubscribe();
    }

    setStatus(!status);
    setInprogress(false);
  }

  return (
    <NotificationsContext.Provider value={ {
      status: status,
      inProgress: inProgress,
      toggleStatus: toggleStatus,
    } }>
      { children }
    </NotificationsContext.Provider>
  );
};

export default NotificationsContext;
