import { PREVIEW_API } from '../Constants';
import { fetchJSONFromAPI } from './api';

const API_ENDPOINT = '/get_revisions';

/**
 * Fetch a preview by ID
 *
 * @param {string} previewId Unique previewId
 * @returns {Promise} resolves to page.
 */
export async function fetchPreviewById(previewId) {
  return fetchJSONFromAPI(
      PREVIEW_API,
      `${API_ENDPOINT}/${previewId}`,
  );
}
