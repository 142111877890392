import React from 'react';
import { useParams } from 'react-router';
import SubNavigation from '../navigation/SubNavigation';
import OfflineView from './OfflineView';
import Title, { TitleContainer } from '../title/Title';
import { urlParser } from '../helpers/url';
import { Back } from '../back/Back';
import ImageOverlay from '../helpers/ImageOverlay';

const BASE_CLASS = 'default-view';

/**
 * @param {{
 *   page: import('../Kalkhoff').Page,
 *   className?: string,
 *   offline?: boolean,
 *   children?: JSX.Element | JSX.Element[],
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function DefaultView({ page, offline, className, children }) {
  /**
   * @type {object}
   * @property {string} slug Unique identifier
   * @property {string} topic Unique identifier
   */
  const { topic, slug } = useParams();

  const baseClass = className || BASE_CLASS;

  if (offline) {
    return (<OfflineView { ...{
      page,
      children,
    } }/>);
  }

  return <article className={ baseClass }>
    <ImageOverlay data={ page }/>
    <TitleContainer showLogo={ true } />
    { topic &&
      <Back
        pageClass={ baseClass }
      />
    }
    <Title
      title={ page.title?.rendered }
      titleClass={ `${baseClass}__title` }
      showLogo={ false }
    />
    <SubNavigation
      topic={ topic || slug }
    />
    { children || <article dangerouslySetInnerHTML={ {
      __html: urlParser(page.content?.rendered),
    } }
    className={ `${baseClass}__content` }/> }
  </article>;
}
