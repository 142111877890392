/** @typedef {import("../Kalkhoff").SparseEvent} SparseEvent */

import { useEffect, useState } from 'react';
import { addLikeToPost, retractLikeFromPost } from '../dao/likes';

const LOCALSTORAGE_KEY = 'calidus.likes';

/**
 * @returns {Object<string, any>} Parsed localStorage item
 */
function getLocalInterest() {
  const localInterestString = localStorage.getItem(LOCALSTORAGE_KEY) || '{}';
  return JSON.parse(localInterestString);
}

/**
 * @param {boolean} interest Wether or not user is interested
 * @param {SparseEvent} event Potentially interesting event
 */
function setLocalInterest(interest, event) {
  const localInterested = getLocalInterest();
  localInterested[event.id] = interest;
  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(localInterested));
}

/**
 * @param {SparseEvent} event Potentially interesting event
 * @returns {[boolean, Function]} [isInterested, setInterested]
 */
export default function useInterest(event) {
  const [ isInterested, setInterested ] = useState(false);

  useEffect(() => {
    const localInterested = getLocalInterest();
    setInterested(localInterested[event?.id] || false);
  }, [ event ]);

  /**
   * @param {boolean} interest Wether or not user is interested
   * @returns {Promise<any>} request to backend
   */
  function guardInterested(interest) {
    setLocalInterest(interest, event);
    setInterested(interest);
    const postToServer = interest ? addLikeToPost : retractLikeFromPost;
    return postToServer(event).catch((e) => {
      // revert
      setLocalInterest(!interest, event);
      setInterested(!interest);
      throw new Error('Failed to update backend, reverting like');
    });
  }

  return [ isInterested, guardInterested ];
}
