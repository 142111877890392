import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changeUserLanguage, fetchUserLanguage } from '../dao/userLanguage';
import createLogger from '../helpers/Logger';

const logger = createLogger('useLanguageSettings');

/**
 *
 * @param {string} id UID for the user (email)
 * @param {string} lang The user language
 * @returns {[
 *   string,
 *   Function
 * ]} Returns the response message and update Function
 */
export default function useLanguageSettings(id, lang) {
  const { i18n } = useTranslation();
  const [ language, setLanguage ] = useState(i18n.language);
  useEffect(() => {
    if (!id) {
      return;
    }
    fetchUserLanguage(id)
        .then((result) => {
          setLanguage(result?.language);
        })
    ;
  }, [ id ]);

  /**
   * @returns {Promise} Resolves when language is updated
   */
  function updateLanguage() {
    return changeUserLanguage(id, lang)
        .then((result) => {
          setLanguage(result?.language);
          logger('setting language to', result?.language);
        });
  }

  return [ language, updateLanguage ];
}
