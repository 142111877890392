import { fetchJSONFromAPI } from './api';
import {
  CORE_API,
  DEFAULT_POST_COUNT,
  INTRANET_API,
  EVENTS_POST_TYPE,
} from '../Constants';

const API_ENDPOINT = '/posts';

/**
 * Fetch all posts
 *
 * @param {string} filter - Category to filter on.
 * @param {number} amount - Amount of posts.
 * @param {Array} excludeIds - Post IDs to exclude
 * @param {string} search - Search query.
 * @param {boolean} sticky - Sticky posts.
 * @param {number} page - Default page to load.
 *
 * @returns {Promise<import('../Kalkhoff').SparsePost[]>} resolves to posts.
 */
export async function fetchPosts(
    filter,
    amount = DEFAULT_POST_COUNT,
    excludeIds = [],
    search = '',
    sticky = false,
    page = 1,
) {
  const params = {
    '_embed': [
      'wp:featuredmedia',
      'wp:term',
    ].join(','),
    '_fields': [
      'categories',
      'date',
      'excerpt',
      'id',
      'slug',
      'title',
      'type',
      'event_custom_fields',
      'like_count',
      'view_count',
      '_links', // This is required to get _embedded props
      'comment_count',
    ].join(','),
  };

  params.page = page;
  params.per_page = amount;

  if (filter) {
    params.categories = filter;
  }

  if (search) {
    params.search = search;
  }

  if (excludeIds.length) {
    params.exclude = excludeIds.join(',');
  }

  if (sticky) {
    params.sticky = sticky;
  }

  // Add extra custom post type events to the endpoint
  params.pon_extra_post_type = EVENTS_POST_TYPE;

  return fetchJSONFromAPI(
      CORE_API,
      API_ENDPOINT,
      params,
  );
}

/**
 * Fetch all posts from Intranet
 *
 * @returns {Promise<import('../Kalkhoff').SparsePost[]>} resolves to posts.
 */
export async function fetchIntranetPosts() {
  return fetchJSONFromAPI(
      INTRANET_API,
      API_ENDPOINT,
      {
        'per_page': '100',
        '_embed': [
          'wp:featuredmedia',
          'wp:term',
        ].join(','),
        '_fields': [
          'categories',
          'date',
          'excerpt',
          'id',
          'slug',
          'title',
          'type',
          '_links', // This is required to get _embedded props
        ].join(','),
      },
  ).catch((error) => {
    console.error('Failed to get cross-posts', error);
    return [];
  });
}

/**
 * Fetch a single post by slug
 *
 * @param {string} slug The unique slug of the post
 * @returns {Promise<import('../Kalkhoff').Post>} resolves to posts.
 */
export async function fetchPostBySlug(slug) {
  const posts = await fetchJSONFromAPI(
      CORE_API,
      API_ENDPOINT,
      {
        '_embed': 'wp:featuredmedia',
        'slug': slug,
      },
  );

  return posts.shift(); // Take the first post of the top
}

/**
 * Fetch a single post from the intranet by slug
 *
 * @param {string} slug The unique slug of the post
 * @returns {Promise<import('../Kalkhoff').Post>} resolves to posts.
 */
export async function fetchIntranetPostBySlug(slug) {
  const posts = await fetchJSONFromAPI(
      INTRANET_API,
      API_ENDPOINT,
      {
        '_embed': 'wp:featuredmedia',
        'slug': slug,
      },
  );

  return posts.shift(); // Take the first post of the top
}
