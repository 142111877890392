import { fetchJSONFromAPI } from './api';
import { CORE_API } from '../Constants';

const API_ENDPOINT = '/pages';

/**
 * Fetch a single page by slug
 *
 * @param {string} slug The unique slug of the page
 * @param {string} topic Unique topic for the page
 * @param {string} subject Unique subject for the page
 * @returns {Promise<import('../Kalkhoff').Page>} resolves to page.
 */
export async function fetchPageBySlug(slug, topic, subject) {
  const params = {
    '_embed': 'wp:featuredmedia',
    'slug': slug,
  };

  if (!topic) {
    params.parent = Number(0);
  }

  let pages = await fetchJSONFromAPI(
      CORE_API,
      API_ENDPOINT,
      params,
  );

  if (topic) {
    // Filter out parent pages
    pages = pages.filter((page) => page.parent !== 0);
  }

  // Take the first page of the top
  return pages.shift();
}
