import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';

const LIFECYCLE = 300000; // 5 minutes

/**
 * @typedef {object} AuthUser
 * @property {string} email Registered email
 * @property {boolean} email_verified Is email verified?
 * @property {string} family_name Family name
 * @property {string} given_name Given name
 * @property {string} name Full name, if specified by Admin / user
 */

let userCache = {
  userClaims: null,
  timestamp: Date.now() - LIFECYCLE,
};

/**
 * @returns {AuthUser|null} Details about the user...
 */
export default function useAuthUser() {
  const { oktaAuth } = useOktaAuth();
  const [ user, setUser ] = useState(userCache.userClaims);

  useEffect(() => {
    oktaAuth.getUser().then((userClaims) => {
      setUser(userClaims);
      userCache = {
        userClaims,
        timestamp: Date.now(),
      };
    });
  }, [ oktaAuth ]);

  return user;
}
