/** @typedef {import('../Kalkhoff').SparsePost} SparsePost */
/** @typedef {import('../Kalkhoff').Reply} Reply */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthUser from '../auth/useAuthUser';
import { sendReply } from '../dao/comments';

/**
 * @typedef {object} ReplyFormProps
 * @property {SparsePost} post Post this reply is parts of
 * @property {Reply} [parent] Optional parent Reply
 * @property {Function} [onReply] optional function callback on succesful reply
 * @property {Function} [setNewComments] add new comment
 * @property {boolean?} [autoFocus] optional auto-focus, false by default
 */

/**
 * @param {ReplyFormProps} arg React props
 * @returns {JSX.Element} Simple reply dialogue
 */
export default function ReplyForm({
  post,
  parent,
  onReply,
  setNewComments,
  autoFocus = false,
}) {
  const [ reply, setReply ] = useState('');
  const user = useAuthUser();
  const [ inProgress, setInProgress ] = useState(!user);
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      setInProgress(false);
    }
  }, [ user ]);

  const userName = user ?
    (user.name || `${user.given_name} ${user.family_name}`) :
    'John Doe'
  ;

  /**
   * @param {import('react').FormEvent} event Form submit event
   */
  function onSubmit(event) {
    event.preventDefault();
    if (!inProgress) {
      setInProgress(true);
      sendReply(
          reply,
          post,
          {
            name: userName,
            email: user?.email || 'john.doe@pon.com',
          },
          parent,
      ).then((res) => {
        onReply?.();
        setReply('');
        setNewComments((prevState) => {
          return [ ...prevState, res ];
        });
      }).finally(() => {
        setInProgress(false);
      });
    }
  }

  return (
    <form className="simple-reply" { ...{ onSubmit } }>
      <textarea
        className="simple-reply__text"
        value={ reply }
        onChange={ ({ target: { value } }) => setReply(value) }
        disabled={ inProgress }
        autoFocus={ autoFocus }
      />
      <input type="submit" className="simple-reply__cta" value={ t('reply') } />
    </form>
  );
}
