import React, { useEffect, useState } from 'react';
import { urlParser } from '../helpers/url';
import { fetchForm } from '../dao/forms';
import FormFactory from '../form/FormFactory';
import DefaultView from './DefaultView';

const BASE_CLASS = 'form-view';

/**
 * @param {{
 *   page: import('../Kalkhoff').Page,
 *   className?: string,
 *   offline?: boolean,
 *   children?: JSX.Element | JSX.Element[],
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function FormView({ page, offline, className }) {
  const [ formData, setFormData ] = useState({});

  const baseClass = className || BASE_CLASS;

  useEffect(() => {
    fetchForm(
        page.kalkhoff_custom_fields.kalkhoff_form_id[0],
    ).then((result) => {
      setFormData(result);
    });
  }, [ page ]);

  return <DefaultView page={ page } offline={ offline }>
    <article dangerouslySetInnerHTML={ {
      __html: urlParser(page.content?.rendered),
    } }
    className={ `${baseClass}__content` }
    />
    <FormFactory formData={ formData } />
  </DefaultView>;
}
