import getGitInfo from 'react-git-info/macro';
// eslint-disable-next-line no-console
const logger = console.log;

const gitInfo = getGitInfo();
const brandLogo = `
\xa0%c                .MMMMMM,                
\xa0               ,MM:  .MMD               
\xa0               MM.    .MM               
\xa0               MM.     MM               
\xa0               IMM.  .MMD               
\xa0    .DMMMMD     .MMMMMMI    %c.$MMMMM.    
\xa0%c   ,MM$..$MM, %c            DMMMD..IMM$   
\xa0%c  .MM.    .MM.%c        .DMMMMM,    .MM.  
\xa0%c  .MM     .MM %c     .DMMM$  MM      MM,  
\xa0%c   DMM    MMD %c  .MMMMMM,   IMM    $MM   
\xa0%c    IMMMMMM8  %c ,MMI. :MM$   ?MMMMMMD    
\xa0%c      '""'    %c MM.    .MM      ""'      
\xa0               MM      MM               
\xa0%c      IMM$    %c IMM.   MMD ,,,$$MM$.     
\xa0%c    MMMMMMMM  %c  'PONDIGITALSOLUTIONS.   
\xa0%c   MMI    IMM %c     '''     DMD    ,MM.  
\xa0%c  .MM      MM %c             MM      MM,  
\xa0%c   MM:    :MM %c     ,,      MM$    .MM   
\xa0%c   .MMM$$MMM. %c  IMMMMMMD    MMM$$MMM,   
\xa0%c     'MMMM:   %c $MM    DMM    .MMMMI     
\xa0               MM      MM..DMMMI        
\xa0               MM.    .MMMMM$           
\xa0               'MMD..IMMMD.             
\xa0                 DMMMMM.                
\xa0                                        
`;

const commonStyles = 'font-weight: bold;';
// For each instance of `%c` add a color statement.
const brandColors = brandLogo.split('%c').map(
    (v, i) => `color: #${i % 2 === 0 ? 'FC8F00' : '1A3FFF'}; ${commonStyles}`,
).slice(0, -1); // Split gives us 1 too many, cutting it of.

export default window.version = () => {
  logger(`Kalkhoff App version ${gitInfo.commit.shortHash}`);
  logger(brandLogo, ...brandColors);
  logger(JSON.stringify(gitInfo));
};
