import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useAuthUser from '../auth/useAuthUser';
import FormFactory from '../form/FormFactory';
import { TitleContainer } from '../title/Title';
import {
  FORMS_ID as FORMS_ID_EN,
  FORMS_ID_DE,
  ACCIDENT_REGISTRATION_FORM_URL,
} from '../Constants';
import { fetchForm } from '../dao/forms';
import useLanguageSettings from '../account/useLanguageSettings';
import useNotificationsContext from '../account/useNotificationsContext';

const BASE_CLASS = 'account-view';
const FORM_IDS = {
  'en': FORMS_ID_EN,
  'de': FORMS_ID_DE,
  'default': FORMS_ID_DE,
};

/**
 * @param {string} locale locale to fetch form for
 *
 * @returns {string} Localized form id
 */
function getFormsID(locale) {
  return FORM_IDS[locale] || FORM_IDS.default;
}

/**
 * @param {{
 *   offline?: boolean,
 * }} props React props
 * @returns {JSX.Element} ...
 */
export default function AccountView(props) {
  const user = useAuthUser();
  const { t, i18n } = useTranslation();
  const name = user?.given_name || null;

  const { status, inProgress, toggleStatus } = useNotificationsContext();
  const [ userLanguage, setUserLanguage ] = useState(i18n.language);
  const [ formData, setFormData ] = useState({});

  const [ language, updateLanguage ] = useLanguageSettings(
      user?.email,
      userLanguage,
  );

  useEffect(() => {
    fetchForm(getFormsID(i18n.language)).then((result) => {
      setFormData(result);
    });
  }, [ i18n.language ]);

  useEffect(() => {
    setUserLanguage(language);
  }, [ language ]);

  /**
   * Update user profile
   */
  async function updateProfile() {
    // Check whether language has changed.
    if (language !== userLanguage) {
      await updateLanguage();
      i18n.changeLanguage(userLanguage);
      window.location.replace(
          `../${userLanguage}/${'en' === userLanguage ? 'contact' : 'kontakt'}`,
      );
    }
  }

  return <article className={ BASE_CLASS }>
    <TitleContainer
      titleClass={ `${BASE_CLASS}__title-container` }
    >
      <h1 className={ `${BASE_CLASS}__title` }>
        <Trans i18nKey="greeting">
          Greetings <em className={ `${BASE_CLASS}__user-name` }>{
            { name }
          }</em>
        </Trans>
      </h1>
    </TitleContainer>

    <p className={ `${BASE_CLASS}__description` }>
      <Trans i18nKey="formDesc">
        If you would like to contact the app team, you
        can easily use the form below. We look forward
        to hearing from you - whether you have questions
        or want to give us feedback.
      </Trans>

      <br/>
      <br/>

      <Trans i18nKey="incidentDesc">
        <span>
          To report an accident, risk or near-accident, follow
          this link to our
        </span>
        <a rel='noopener noreferrer' href={ ACCIDENT_REGISTRATION_FORM_URL }>
          accident form
        </a>.
      </Trans>
    </p>

    <FormFactory formData={ formData } />

    <h2 className={ `${BASE_CLASS}__topic` }>
      <Trans i18nKey="language">
        <em>Language</em>
      </Trans>
    </h2>
    <select
      className={ `${BASE_CLASS}__language` }
      onChange={ (event) => {
        setUserLanguage(event.target.value);
      } }
      value={ userLanguage }
    >
      <option value="en">
        { t('english') }
      </option>
      <option value="de">
        { t('german') }
      </option>
    </select>

    <h2 className={ `${BASE_CLASS}__topic` }>
      <Trans i18nKey="notifications">
        <em>Notifications</em>
      </Trans>
    </h2>
    <div>
      <label className={ `${BASE_CLASS}__notifications` }>
        <div className="toggle">
          <input
            type="checkbox"
            checked={ status }
            disabled={ inProgress }
            onChange={ toggleStatus }
          />
          <span />
        </div>
        <em className={ `${BASE_CLASS}__label` }>
          { status ? t('notificationsOn') : t('notificationsOff') }
        </em>
      </label>
    </div>
    <div className={ `${BASE_CLASS}__phone` }>
      <button
        className={ `${BASE_CLASS}__phone--submit` }
        onClick={ updateProfile }
      >
        { t('Submit') }
      </button>
    </div>
  </article>;
}
