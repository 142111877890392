import React from 'react';
import HiddenField from './HiddenField';
import TextArea from './TextArea';
import TextField from './TextField';
import RadioGroup from './RadioGroup';
import useTextField from './useFormField';
import FileUpload from './FileUpload';

const HIDDEN = 'hidden';

const FORM_FIELD_TYPES = {
  [HIDDEN]: HiddenField,
  radio: RadioGroup,
  textarea: TextArea,
  fileupload: FileUpload,
  default: TextField,
};

const CONTIONAL_OPERATORS = {
  'is': function(fieldValue, matchFieldValue, ruleValue) {
    return matchFieldValue !== ruleValue;
  },
};

/**
 * @param {Object<string, any>} field Field definition
 * @param {{
 *  operator: string,
 *  value: string,
 * }} rule The 'rule' to apply
 * @param {string} fieldValue The value of the field
 * @param {string} matchFieldValue The value of the field to match with
 * @returns {boolean} `true` when field should be hidden
 */
function isHiddenConditionalLogic(field, rule, fieldValue, matchFieldValue) {
  if (field?.conditionalLogic?.actionType === 'show') {
    if (rule) {
      const operator = CONTIONAL_OPERATORS[rule.operator] || (() => true);
      return operator(fieldValue, matchFieldValue, rule.value);
    }
  }
  return false;
}

/**
 * @param {{
 *  field: Object<string, any>
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function FormFieldFactory({ field }) {
  const rule = field?.conditionalLogic?.rules?.[0];
  const { value: matchFieldValue } = useTextField(rule ? rule.fieldId : {});
  const { value: fieldValue } = useTextField(field.id);
  const hidden = field?.visibility === HIDDEN;

  const FormFieldType =
    hidden ? FORM_FIELD_TYPES[HIDDEN] :
    FORM_FIELD_TYPES[field.type] || FORM_FIELD_TYPES.default
  ;
  if (isHiddenConditionalLogic(
      field,
      rule,
      String(fieldValue),
      String(matchFieldValue),
  )) {
    return null;
  }

  return <FormFieldType field={ field } />;
}
