import React from 'react';
import { useParams } from 'react-router';
import CategoriesList from '../categories/CategoriesList';
import TimeStamp from '../time/TimeStamp';
import { useIntranetPost } from './useIntranetPosts';
import { urlParser } from '../helpers/url';
import { Trans } from 'react-i18next';
import { Back } from '../back/Back';

/**
 * @param {{
 *   post: import('../Kalkhoff').Post | import('../Kalkhoff').SparsePost,
 *   likeButton: JSX.Element,
 * }} arg React props
 * @returns {JSX.Element} React component
 */
export function BasePostView({ post }) {
  /** @type {[Function, Function]} */

  if (!post) {
    return <div className="progress-spinner">
      <Trans i18nKey="inProgress">
        <em>In progress</em>
      </Trans>
    </div>;
  }

  const postStyle = {
    backgroundImage: `url('${
      post._embedded?.['wp:featuredmedia']?.[0].source_url
    }')`,
  };

  return <article className='post-view'>
    <Back
      pageClass='post-view'
    />

    <div className="post-view__media" style={ postStyle } />
    <CategoriesList post={ post } />
    <h2 className="post-view__title" dangerouslySetInnerHTML={ {
      __html: post.title?.rendered,
    } } />
    <TimeStamp date={ post.date } className="post-view__timestamp" />
    <div className="post-view__content" dangerouslySetInnerHTML={ {
      // REASON: optional chaining resolves Sparse vs full type
      // @ts-ignore
      __html: urlParser(post.content?.rendered),
    } } />

  </article>;
}

/**
 * @param {{
 *   hook?: Function,
 * }} arg React props
 * @returns {JSX.Element} React component
 */
export default function IntranetPostView({ hook }) {
  /**
   * @type {object}
   * @property {string} slug Unique identifier
   */
  const { slug } = useParams();

  /** @type {import('../Kalkhoff').Post | import('../Kalkhoff').SparsePost} */
  const post = useIntranetPost(slug);

  return <BasePostView
    post={ post }
  />;
}
