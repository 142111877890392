/** @typedef {import('../Kalkhoff').SparseEvent} SparseEvent */
/** @typedef {import('../Kalkhoff').Event} Event */
import { convert, LocalDate } from '@js-joda/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchEvents, fetchEventBySlug } from '../dao/events';

const dateTimeOptions = {
  month: 'long',
};

/**
 * @param {SparseEvent} event A singular event
 * @param {i18n} i18n The i18n instance containing locale
 * @returns {string} the month from the datestamp
 */
function getMonth(event, i18n) {
  const locale = i18n.language;
  const date = String(event.event_custom_fields.callidus_event_date);
  const localDate = LocalDate.parse(date);
  // Convert to JavaScript `Date` object
  const jsDate = convert(localDate).toDate();
  return jsDate.toLocaleDateString(locale, dateTimeOptions);
}

/**
 * Basic hook to fetch events from the server.
 *
 * @returns {Array<import('../Kalkhoff').SparseEvent[]>}
 *          returns the `posts` state.
 */
export default function useEvents() {
  const [ posts, setPosts ] = useState([]);

  useEffect(() => {
    fetchEvents()
        .then((posts) => setPosts(posts))
    ;
  }, []);

  return [ posts ];
}

/**
 * Hook to divide the events by month
 *
 * @returns {Array<import('../Kalkhoff').SparseEvent[]>}
 *          returns the `events` state.
 */
export function useEventsByMonth() {
  const [ events ] = useEvents();
  const [ groupedEvents, setGroupedEvents ] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const sortedEvents = events.sort((a, b) => {
      const aDate = String(a.event_custom_fields.callidus_event_date);
      const bDate = String(b.event_custom_fields.callidus_event_date);
      return aDate < bDate ? 1 : aDate > bDate ? -1 : 0;
    });

    const groupedEvents = sortedEvents.reduce((acc, event) => {
      const month = getMonth(event, i18n);
      return {
        ...acc,
        [month]: [
          ...(acc[month] || []),
          event,
        ],
      };
    }, {});
    setGroupedEvents(groupedEvents);
  }, [ events, i18n ]);

  return [ groupedEvents ];
}

/**
 * @param {string} slug Unique slug for the event
 * @returns {Event | SparseEvent} Returns an `Event` as a state-controlled value
 */
export function useEvent(slug) {
  const [ event, setEvent ] = useState(null);

  useEffect(() => {
    fetchEventBySlug(slug)
        .then((event) => setEvent(event || null))
    ;
  }, [ slug ]);

  return event;
}
