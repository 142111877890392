/**
 * Set values to specific keys so we can re-use the default view.
 *
 * @param {Object} previewData The preview data
 * @param {string} type Type of data we get
 *
 * @returns {Object} The formatted preview data
 */
export function formatPreviewData(previewData, type) {
  previewData.title = {};
  previewData.content = {};
  previewData.title.rendered = previewData?.post_title;
  previewData.content.rendered = previewData?.post_content;

  if ('post' === type) {
    // Change the revision ID to the post ID.
    // Without this the replies won't work.
    previewData.id = previewData?.post_parent;
  }

  return previewData;
}
