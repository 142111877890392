const en = {
  translation: {
    greeting: 'Hello, <1>{{name}}</1>',
    reply: 'Reply',
    interested: 'Interested',
    notifications: 'Notifications',
    back: 'Back',
    inProgress: 'In progress',
    toggleNotification: 'I want to receive notifications on my phone',
    formDesc: 'If you would like to contact the app team, you ' +
      'can easily use the form below. We look forward ' +
      'to hearing from you - whether you have questions ' +
      'or want to give us feedback.',
    incidentDesc: 'To report an accident, risk or near-accident, ' +
      'follow this link to our <1>accident form<1>.',
    phoneTitle: 'Phone number',
    phoneDesc: 'The phone number including the country code (e.g. +491...)',
    submit: 'Submit',
    allPosts: 'All posts',
    language: 'Language',
    english: 'English',
    german: 'German',
    noPosts: 'There are currently no posts available.',
    upload: 'Upload',
    loadMore: 'Load more',
    allHrNews: 'Show all HR news',
    searchPosts: 'Search posts',
    notFound: 'The page you are looking for does not exist (anymore).',
    backToStart: 'Go back to the start screen.',
    invalidForm: 'The following field is required: <1>{{invalidInput}}</1>',
    notificationsOn: 'Messages activated',
    notificationsOff: 'Messages deactivated',
  },
};

export default en;
