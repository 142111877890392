import React from 'react';
import Title, { TitleContainer } from '../title/Title';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BASE_CLASS = 'not-found';

/**
 * @returns {JSX.Element} ...
 */
export default function NotFound() {
  const baseClass = BASE_CLASS;
  const { t } = useTranslation();

  return <article className={ baseClass }>
    <TitleContainer showLogo={ true } />
    <Title
      title="WHOOPS! ERROR."
      titleClass={ `${baseClass}__title` }
      showLogo={ false }
    />
    <p>
      { t('notFound') }
    </p>
    <Link to="/">
      { t('backToStart') }
    </Link>
  </article>;
}
