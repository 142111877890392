import React from 'react';
import { Link } from 'react-router-dom';
import logo from './../assets/images/KH_Word_black.jpg';
/**
 * @param {{
 *   titleClass?: string,
 *   children?: JSX.Element | JSX.Element[],
 *   showLogo?: boolean
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export function TitleContainer({ children, titleClass = '', showLogo = true }) {
  return <div className={ `main-title ${titleClass}` }>
    { children }
    { showLogo &&
      <Link
        to="/"
        className="main-title__link">
        <img
          className="main-title__logo"
          src={ logo }
          alt="Kalkhoff"
        />
      </Link>
    }
  </div>;
}

/**
 * @param {{
 *   title: string,
 *   titleClass: string,
 *   showLogo?: boolean
 * }} arg React props
 *
 * @returns {JSX.Element} React component
 */
export default function Title({ title, titleClass, showLogo }) {
  return <TitleContainer titleClass={ titleClass } showLogo={ showLogo }>
    <h1 dangerouslySetInnerHTML={ {
      __html: title,
    } }/>
  </TitleContainer>;
}
