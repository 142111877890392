import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useAuthUser from './auth/useAuthUser';
import PostsFilter from './posts/PostsFilter';
import PostsList from './posts/PostsList';
import PostsSearch from './posts/PostsSearch';
import { TitleContainer } from './title/Title';

/**
 * @returns {JSX.Element} React Component
 */
export default function HomeView() {
  const [ search, setSearch ] = useState(
      new URLSearchParams(useLocation()?.search).get('search') ?? '',
  );
  const user = useAuthUser();
  // We're in an authenticated view, the user will resolve, until then
  // we cannot access given_name...
  // (CSS placeholder is used , so that we don't get jumping content)
  const name = user?.given_name || null;
  const categoryFilter = new URLSearchParams(useLocation()?.search)
      .get('category') ?? '';

  return <article className="home-view">
    <TitleContainer titleClass='home-view__title-container' showLogo={ false }>
      <h1 className="home-view__title">
        <Trans i18nKey="greeting">
          Greetings <em className="home-view__user-name">{ { name } }</em>
        </Trans>
      </h1>
      <div className="home-view__filter-container">
        <PostsFilter
          selected={ categoryFilter }
        />
        <PostsSearch
          setSearch={ setSearch }
        />
      </div>
    </TitleContainer>
    <PostsList
      filter={ categoryFilter }
      search={ search }
    />
  </article>;
}
