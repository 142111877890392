import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import CategoriesList from '../categories/CategoriesList';
import RepliesList from '../comments/RepliesList';
import ReplyForm from '../comments/ReplyForm';
import TimeStamp from '../time/TimeStamp';
import { usePost, usePreviewPost } from './usePosts';
import Icon from '../icons/Icon';
import useLike from './useLike';
import useView from './useView';
import { urlParser } from '../helpers/url';
import { Trans } from 'react-i18next';
import { Back } from '../back/Back';
import RelatedPosts from './RelatedPosts';
import { formatPreviewData } from '../helpers/Preview';
import ImageOverlay from '../helpers/ImageOverlay';

const like = 'icon-thumbs-up';

/**
 * @param {{
 *   post: import('../Kalkhoff').Post | import('../Kalkhoff').SparsePost,
 *   likeButton: JSX.Element,
 * }} arg React props
 * @returns {JSX.Element} React component
 */
export function BasePostView({ post, likeButton }) {
  const [ newComments, setNewComments ] = useState([]);
  // Second parameter isn't used, so we won't define a variable to it.
  const [ isViewed, , countView, isViewsInitialized ] = useView(post);

  useEffect(() => {
    if (isViewsInitialized && !isViewed) {
      countView(true);
    }
  }, [ post, isViewsInitialized, countView, isViewed ]);

  if (!post) {
    return <div className="progress-spinner">
      <Trans i18nKey="inProgress">
        <em>In progress</em>
      </Trans>
    </div>;
  }

  const postStyle = {
    backgroundImage: `url('${
      post._embedded?.['wp:featuredmedia']?.[0].source_url
    }')`,
  };

  return <article>
    <div className='post-view'>
      <Back
        pageClass='post-view'
      />

      <div className="post-view__media" style={ postStyle } />
      <CategoriesList post={ post } />
      <ImageOverlay data={ post }/>
      <h2 className="post-view__title" dangerouslySetInnerHTML={ {
        __html: post.title?.rendered,
      } } />
      <TimeStamp date={ post.date } className="post-view__timestamp" />
      <div className="post-view__content" dangerouslySetInnerHTML={ {
        // REASON: optional chaining resolves Sparse vs full type.
        // @ts-ignore
        __html: urlParser(post.content?.rendered),
      } } />

      { likeButton }

      <ReplyForm { ...{
        post,
        setNewComments,
      } }/>

      <RepliesList { ...{
        post,
        setNewComments,
        newComments,
      } } />
    </div>

    <RelatedPosts post={ post } />
  </article>;
}

/**
 * @param {{
 *   hook?: Function,
 * }} arg React props
 * @returns {JSX.Element} React component
 */
export default function PostView({ hook }) {
  /**
   * @type {object}
   * @property {string} slug Unique identifier
   */
  const { slug } = useParams();
  const history = useHistory();

  // Check if there is a preview_id.
  const previewId = new URLSearchParams(useLocation()?.search)
      .get('preview_id');

  // Use react-hook conditionally to prevent unnecessary API calls.
  // eslint-disable-next-line react-hooks/rules-of-hooks, max-len
  let { post, dataLoading } = previewId ? usePreviewPost(previewId) : usePost(slug);

  if (previewId && post) {
    post = formatPreviewData(post, 'post');
  }

  const [ isLiked, likesCount, setLiked ] = useLike(post);

  if (post === null) {
    if (dataLoading) {
      return null;
    } else {
      history.push('/404');
      return null;
    }
  }

  /**
   * @param {import('react').SyntheticEvent} e Actual mouse-event
   */
  function clickHandler(e) {
    e.stopPropagation();
    e.preventDefault();
    setLiked(!isLiked);
  }

  return <BasePostView
    post={ post }
    likeButton={ (<a
      href="#like"
      rel="like"
      className={ `post-view__star${isLiked ? '--liked' : ''}` }
      onClick={ clickHandler }
    ><Icon icon={ like }/>{ likesCount }</a>) }
  />;
}
