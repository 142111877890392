import { fetchJSONFromMeta, postJSONToMeta } from './api';

/**
 * Fetch the counter for a specific post.
 *
 * @param {string} API_ENDPOINT The counter endpoint
 * @param {import('../Kalkhoff').SparsePost} post
 *        The post for which to fetch count
 * @returns {Promise<any>}
 *          Resolves to the count
 */
export async function fetchCountsByPost(API_ENDPOINT, post) {
  const results = await fetchJSONFromMeta(
      `${API_ENDPOINT}/${[ post.id ]}`,
  );

  const replies = results;

  return replies || []; // Just in case
}

/**
 * @param {string} API_ENDPOINT The counter endpoint
 * @param {import('../Kalkhoff').SparsePost} post
 *        The post for which to add a count
 * @returns {Promise<any>}
 *          Resolves to the count
 */
export async function addCountToPost(API_ENDPOINT, post) {
  return postJSONToMeta(
      `${API_ENDPOINT}/${[ post.id ]}`,
  );
}

/**
 * @param {string} API_ENDPOINT The counter endpoint
 * @param {import('../Kalkhoff').SparsePost} post
 *        The post for which to retract a count
 * @returns {Promise<any>}
 *          Resolves to the count
 */
export async function retractCountFromPost(API_ENDPOINT, post) {
  return postJSONToMeta(
      `${API_ENDPOINT}/${[ post.id ]}`,
      '',
      {},
      {
        method: 'DELETE',
      },
  );
}

