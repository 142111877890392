import React, { useEffect, useState } from 'react';
import Icon from '../icons/Icon';

/**
 * @param {{
 *   data: import('../Kalkhoff').Page | import('../Kalkhoff').Post,
 * }} arg React props
 * @returns {JSX.Element} ...
 */
export default function ImageOverlay({ data }) {
  const [ imgSrc, setImgSrc ] = useState('');

  useEffect(() => {
    const handleClick = (event) => {
      setImgSrc(event.target.src);
    };

    const galleries = document.getElementsByClassName('wp-block-gallery');

    // If there is a gallery available...
    if (galleries) {
      // Loop over all galleries.
      for (const gallery of galleries) {
        const galleryImages = gallery.getElementsByTagName('img');

        // Loop over all images in a single gallery.
        for (const image of galleryImages) {
          image.addEventListener('click', handleClick);
        }
      }
    }
  }, [ data ]);

  return (
    <>
      { imgSrc &&
        <div
          className="image-overlay"
          onClick={ () => setImgSrc('') }
        >
          <div className="image-overlay__wrapper">
            <Icon
              icon="icon-close"
            />
            <img
              className="image-overlay__wrapper--image"
              alt="Test"
              src={ imgSrc }
            />
          </div>
        </div>
      }
    </>
  );
}
