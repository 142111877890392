import React from 'react';
import InProgress from '../helpers/InProgress';
import { JSXFeed } from '../pages/FeedView';
import ScheduleEventBlock from './ScheduleEventBlock';
import { useEventsByMonth } from './useEvents';

/**
 * @typedef {object} ViewProps
 * @property {import('../Kalkhoff').Page} page ...
 * @property {boolean} offline `true` if app is offline
 */

/**
 * @param {Object} props React props
 * @param {string} props.month The numeric month, as a string
 * @param {import('../Kalkhoff').SparseEvent[]} props.events List of events
 * @returns {JSX.Element} ...
 */
function SchedultEventList({ month, events }) {
  return <li className="events-schedule__month">
    <h4 className="events-schedule__title">{ month }</h4>
    <ol className="events-schedule__list">
      { events.map((event) => (
        <ScheduleEventBlock
          key={ event.id }
          className='scheduled-event'
          post={ event }
        />
      )) }
    </ol>
  </li>;
}

/**
 * Return feed of custom events type
 *
 * @param {ViewProps} props React props
 * @returns {JSX.Element} ...
 */
export default function EventsSchedule(props) {
  const [ events ] = useEventsByMonth();
  if (!events) {
    // Meanwhile, show a spinner (never seen it, navitems are in too fast)
    return <InProgress inProgress={ true } />;
  }
  if (events.length <= 0) {
    return null;
  }
  return <JSXFeed
    { ...props }
    CSSclass="events-schedule"
  >
    {
      Object.keys(events).map(((month) => (
        <SchedultEventList
          key={ month }
          month={ month }
          events={ events[month] }
        />
      )))
    }
  </JSXFeed>;
}

