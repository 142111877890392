import React, { useEffect, useState } from 'react';
import useCategories from './useCategories';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CORONA_CATEGORY_ID_DE, CORONA_CATEGORY_ID_EN } from '../Constants';

/**
 * @param {{
 *   selected: string,
 * }} arg React props.
 *
 * @returns {JSX.Element} The goods
 */
export default function PostsFilter({ selected }) {
  const [ selectedFilter, setSelectedFilter ] = useState(selected);

  const [ categories ] = useCategories();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const categoryExcludeIds = [
    CORONA_CATEGORY_ID_DE,
    CORONA_CATEGORY_ID_EN,
  ];

  useEffect(() => {
    setSelectedFilter(selected);
  }, [ selected ]);

  /**
   * Change the post filter
   *
   * @param {{
   *  target: {
   *    value: string,
   *  }
   * }} event Change event
   */
  function filterPosts(event) {
    setSelectedFilter(event.target.value);
    const queryParams =
      new URLSearchParams(location?.search).get('search') ?? '';
    const searchParam = queryParams ? `?search=${queryParams}&` : '?';
    history.push({
      search: `${searchParam}category=${
        event.target.value
      }`,
    });
  }

  return <select
    className="posts-filter"
    onChange={ filterPosts }
    value={ selectedFilter }
  >
    <option value="">
      { t('allPosts') }
    </option>
    {

      categories.map((category) => {
        // Skip the category if the category ID should be excluded.
        if (categoryExcludeIds.includes(String(category.id))) {
          return null;
        } else {
          return <option key={ category.id } value={ category.id }>
            { category.name }
          </option>;
        }
      })
    }
  </select>;
}
