/** @typedef {import('../Kalkhoff').Category} Category */
import { useEffect, useState } from 'react';
import { fetchCategories } from '../dao/categories';

/**
 * Stick categories in a cache, so we only need to fetch them once.
 * Will always return a Promise, resolving to the actual categories.
 */
const fetchCachedCategories = (function categoriesLimbo() {
  /** @type {Category[]} */
  let categories = null;
  let promise = null;

  /**
   * @returns {Category[]} The categories
   */
  function chain() {
    return categories;
  }

  /**
   * @returns {Promise<Category[]>}
   *          Resolves to array of categories
   */
  return function fetchFromLimbo() {
    if (categories !== null) {
      return Promise.resolve(categories);
    }

    if (promise !== null) {
      return promise.then(chain);
    }

    promise = fetchCategories().then(
        function catchAndRelease(results) {
          categories = results;
          promise = null;
          return categories;
        },
    );

    return promise;
  };
})();

/**
 * @param {number} uid Unique id of category
 * @returns {Category | null} A category
 */
export function useCategory(uid) {
  /** @type {[Category | null, Function]} */
  const [ category, setCategory ] = useState(null);

  useEffect(function onMount() {
    fetchCachedCategories().then(
        (results) => setCategory(
            results.find((category) => category.id === uid),
        ),
    );
  }, [ uid ]);

  return category;
}

/**
 * @returns {Category[] | null}
 *          List of categories, or null.
 */
export default function useCategories() {
  const [ categories, setCategories ] = useState(null);

  useEffect(function onMount() {
    fetchCachedCategories().then(
        function cacheCategories(results) {
          setCategories(results);
        },
    );
  }, []);

  return categories;
}
