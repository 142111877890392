/** @typedef {import('../Kalkhoff').Reply} Reply */
/** @typedef {import('../Kalkhoff').SparsePost} SparsePost */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeStamp from '../time/TimeStamp';
import ReplyForm from './ReplyForm';
import { useRepliesByPost } from './useRepliesByPost';

/**
 * @param {{
 *   answer: Reply,
 * }} arg React props
 * @returns {JSX.Element} A super sparse Reply
 */
function Answer({
  answer,
}) {
  return <div className="answer">
    <span className="answer__name">{ answer.author_name }</span>
    <div className="answer__content" dangerouslySetInnerHTML={ {
      __html: answer.content.rendered,
    } } />

  </div>;
}

/**
 * @param {{
 *   show: boolean,
 *   post: SparsePost,
 *   parent: Reply,
 *   onReply: Function,
 *   setNewComments: Function,
 * }} arg React props
 * @returns {null|JSX.Element} The option to reply
 */
function Reply({
  show,
  post,
  parent,
  onReply,
  setNewComments,
}) {
  if (!show) {
    return null;
  }

  return <ReplyForm { ...{
    post,
    parent,
    setNewComments,
    onReply,
    autoFocus: true,
  } }/>;
}

/**
 * Show a listing of a single post
 *
 * @param {{
 *   comment: Reply,
 *   post: SparsePost,
 *   replies: Reply[],
 *   setNewComments: Function,
 * }} arg React props
 * @returns {JSX.Element} An `article` representing a single post.
 */
function Comment({
  comment,
  post,
  replies,
  setNewComments,
}) {
  const [ reply, setReply ] = useState(false);
  const [ answers, setAnswers ] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setAnswers(replies.filter((r) => r.parent === comment.id));
  }, [ replies, comment ]);

  /**
   * @param {{
   *   preventDefault: Function,
   * }} e Just a mouse-event
   */
  function onClickHandler(e) {
    e.preventDefault();
    setReply(!reply);
  }

  return (
    <section className="replies-list__container">
      <article
        className="replies-list__comment"
      >
        <h3 className="replies-list__name">{ comment.author_name }</h3>
        <div className="replies-list__excerpt" dangerouslySetInnerHTML={ {
          __html: comment.content.rendered,
        } } />
      </article>
      <TimeStamp date={ comment.date } className="replies-list__timestamp" />
      <a
        className="replies-list__action"
        href="#reply"
        onClick={ onClickHandler }
      >{ t('reply') }</a>
      <Reply
        show={ reply }
        post={ post }
        parent={ comment }
        onReply={ () => setReply(false) }
        setNewComments={ setNewComments }
      />
      <nav className="replies-list--sub">{ answers.map((answer) => (
        <Answer key={ answer.id } answer={ answer } />
      )) }</nav>
    </section>
  );
}

/**
 * @param {{
 *   post: SparsePost,
 *   setNewComments: Function,
 *   newComments: Array,
 * }} arg React props
 * @returns {JSX.Element} The goods
 */
export default function RepliesList({
  post,
  setNewComments,
  newComments,
}) {
  const [ replies ] = useRepliesByPost(post);
  const [ rootReplies, setRootReplies ] = useState([]);

  useEffect(() => {
    setRootReplies([ ...replies, ...newComments ]);
  }, [ replies, newComments ]);

  return (
    <nav className="replies-list">
      { rootReplies.filter((r) => r?.parent === 0).map((reply) => (
        <Comment
          key={ reply.id }
          comment={ reply }
          replies={ rootReplies }
          { ...{
            post,
            setNewComments,
          } }
        />
      )) }
    </nav>
  );
}
