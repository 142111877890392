import { FRONTEND_URL, BACKEND_URL } from '../Constants';

/**
 * Refactor urls from API
 *
 * @param {string} url The default url
 * @returns {string} The refactored url.
 */
export function refactorUrl(url) {
  let strippedUrl;

  if (url && url.includes(FRONTEND_URL)) {
    return (strippedUrl = url.replace(FRONTEND_URL, ''));
  }
  if (url && url.includes(BACKEND_URL)) {
    // eslint-disable-next-line no-unused-vars
    return (strippedUrl = url.replace(BACKEND_URL, ''));
  }

  return url;
}

/**
 * Refactor urls in content from API
 *
 * @param {string} content The content from the API
 * @returns {string} The refactored content.
 */
export function urlParser(content) {
  const defaultUrl = BACKEND_URL;
  const translatedUrl = FRONTEND_URL || defaultUrl;
  // eslint-disable-next-line max-len
  const urlRegEx = /((\b(https?):\/\/|www)[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;

  const cleanedContent = content?.replace(urlRegEx, function(url) {
    if (url.match(/\.(jpg|png|pdf|gif)$/i) || defaultUrl === translatedUrl) {
      return url;
    } else {
      const rewriteUrl = url.replace(defaultUrl, translatedUrl);
      return rewriteUrl;
    }
  });
  return cleanedContent;
}
