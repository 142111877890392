import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Offline, Online } from 'react-detect-offline';
import PostView from './posts/PostView';
import IntranetPostView from './posts/IntranetPostView';
import NavigationView from './navigation/NavigationView';
import PageView from './pages/PageView';
import EventView from './events/EventView';
import { GA_TRACKING_ID, OKTA_CLIENT_ID, OKTA_DOMAIN } from './Constants';
import ReactGA from 'react-ga4';
import './i18n';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import HomeView from './HomeView';
import FormView from './form/FormView';
import Installbanner from './pwa/Installbanner';
import SlugRouter from './pages/SlugRouter';
import AboutView from './helpers/AboutView';
import LanguageDetector from './i18n/LanguageDetector';
import AccountView from './pages/AccountView';
import EventRouter from './pages/EventRouter';
import NotFound from './pages/NotFound';
import { NotificationsProvider } from './contexts/NotificationsContext';

export const oktaAuth = new OktaAuth({
  issuer: `https://${OKTA_DOMAIN}/`,
  clientId: OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
});

const OFFLINE_POLLING = {
  enabled: false,
};

const base = '/:locale(en|de)';

/**
 * Trigger the serviceWorker to test and potentially refresh
 * offline content.
 */
function triggerOfflineRefresh() {
  navigator?.serviceWorker?.controller?.postMessage(JSON.stringify({
    action: 'REFRESH_OFFLINE_CONTENT',
  }));
}

/**
 * @param {{
 *   keyCode: number,
 *   ctrlKey: boolean,
 *   target: EventTarget
 * }} arg A basic keydownevent
 */
function detectFormSubmitByShortCut({
  keyCode,
  ctrlKey,
  target,
}) {
  if (ctrlKey && keyCode === 13) {
    const input = /** @type {HTMLFormElement} */ (target);
    input?.form?.querySelector('input[type="submit"]')?.click();
  }
}

/**
 * Hit GA for a page-view
 */
function trackPageView() {
  // Using window.location because this listener is not receiving
  // the parameters defined in it's Typescript
  const page = window.location.pathname;
  ReactGA.set({ page });
  ReactGA.pageview(page);
}

/**
 * This is the main entry-point into the app.
 *
 * @returns {JSX.Element} The app container
 */
function App() {
  const location = useLocation();

  useEffect(() => {
    const { hash } = window.location;

    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      const timeout = setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        const headerOffset = 100;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
        });
      }, 0);

      return () => clearTimeout(timeout);
    }
  }, [ location ]);

  useEffect(() => {
    triggerOfflineRefresh();
    document.addEventListener('keydown', detectFormSubmitByShortCut);
    if (window.location.origin.includes('https://app.kalkhoff-werke.com')) {
      ReactGA.initialize(GA_TRACKING_ID);
      trackPageView();
    }
  }, []);
  const history = useHistory();

  if (window.location.origin.includes('https://app.kalkhoff-werke.com')) {
    history.listen(trackPageView);
  }

  /**
   * Newer Okta requires you to add your own `restoreOriginalUri` implementation
   * To restore the uri we need `history` and for that we need to be a
   * _descendant_ of <Router>.
   *
   * @param {OktaAuth} _oktaAuth OktaOauth instance passed in by okta
   */
  async function restoreOriginalUri(_oktaAuth) {
    // Always redirect to the homepage.
    history.replace(toRelativeUrl('', window.location.origin));
  }

  return (
    <Security oktaAuth={ oktaAuth } restoreOriginalUri={ restoreOriginalUri }>
      <NotificationsProvider>
        <div className="App">
          <NavigationView />
          <Online polling={ OFFLINE_POLLING }><Switch>
            <Route path='/login/callback' component={ LoginCallback } />
            <Route path={ `${base}/about` } component={ AboutView } />
            <SecureRoute path={ `${base}/404` }>
              <NotFound />
            </SecureRoute>
            <SecureRoute path={ `${base}/post/:slug` }>
              <PostView />
            </SecureRoute>
            <SecureRoute path={ `${base}/feedback` }>
              <FormView />
            </SecureRoute>
            <SecureRoute path={ `${base}/:topic/feedback` }>
              <FormView />
            </SecureRoute>
            <SecureRoute path={ '/en/contact' }>
              <AccountView />
            </SecureRoute>
            <SecureRoute path={ '/de/kontakt' }>
              <AccountView />
            </SecureRoute>
            <SecureRoute path={ `${base}/callidus-events/:slug` }>
              <EventRouter />
            </SecureRoute>
            <SecureRoute path={ `${base}/events/:slug` }>
              <EventView />
            </SecureRoute>
            <SecureRoute path={ `${base}/intranet/:slug` }>
              <IntranetPostView />
            </SecureRoute>
            <SecureRoute path={ `${base}/:subject/:topic/:slug` }>
              <PageView />
            </SecureRoute>
            <SecureRoute path={ `${base}/:topic/:slug` }>
              <PageView />
            </SecureRoute>
            <SecureRoute path={ `${base}/:slug` }>
              <SlugRouter />
            </SecureRoute>
            <SecureRoute path={ base }>
              <HomeView />
            </SecureRoute>
            <Route path='/' component={ LanguageDetector } />
          </Switch></Online>
          <Offline polling={ OFFLINE_POLLING }><Switch>
            <Route path={ `${base}/:topic/:slug` }>
              <PageView offline={ true } />
            </Route>
            <Route path={ base }>
              <PageView offline={ true } />
            </Route>
            <Route path='/' component={ LanguageDetector } />
          </Switch></Offline>
          <Installbanner />
        </div>
      </NotificationsProvider>
    </Security>
  );
}

export default App;
