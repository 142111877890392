import createLogger from '../helpers/Logger';
import i18n from '../i18n';
import { fetchNavFromAPI } from './api';

const log = createLogger('navigation dao');
const API_ENDPOINT = '/menus';
let navConfig = null;

/**
 * @param {string} location The location of the navigation
 * @returns {JSON} The meta field of the menu-item, language corrected
 */
async function getLocationConfig(location) {
  if (navConfig === null) {
    navConfig = await fetchNavFromAPI(
        API_ENDPOINT,
        '?_fields=slug,meta',
    );
  }
  const lang = i18n.language;
  const actualLocation = lang.startsWith('en') ? location : [
    location,
    lang,
  ].join('-');
  const allLanguages = navConfig.filter(
      (menu) => menu.slug.startsWith(location),
  ).reduce(
      (acc, menu) => ({ ...acc, [menu.slug]: menu.meta }),
      {},
  );
  if (allLanguages[actualLocation]) {
    return allLanguages[actualLocation];
  }
  log(navConfig, allLanguages);
  return allLanguages[location];
}

/**
 * @param {string} location The location of the navigation
 * @returns {string} location-id for correct language (or default)
 */
async function getLocationID(location) {
  const config = await getLocationConfig(location);
  return config?.links.self.split('/').pop();
}

/**
 * Fetch all posts
 *
 * @param {string} location The location of the navigation
 * @returns {Promise<import('../Kalkhoff').Navitem[]>} resolves to navigation.
 */
export async function fetchNavigationByLocation(location) {
  const locationID = await getLocationID(location);
  log(location, locationID);
  if (!locationID) {
    return [];
  }
  return fetchNavFromAPI(
      API_ENDPOINT,
      locationID,
  ).then(
      (menu) => menu.items,
  ).catch((response) => {
    console.warn('/menu-locations failed to resolve', response);
    return [];
  });
}
