import {
  addViewToPost,
  retractViewFromPost,
  VIEWS_CUSTOM_FIELDNAME,
} from '../dao/views';
import useCounter from '../helpers/useCounter';

/**
 * @param {import('../Kalkhoff').SparsePost} post Potentially liked post
 * @returns {[boolean, string, Function, boolean]} [isLiked, setLiked]
 */
export default function useView(post) {
  return useCounter(
      post,
      VIEWS_CUSTOM_FIELDNAME,
      addViewToPost,
      retractViewFromPost,
  );
}
