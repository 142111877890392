export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

export const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;

export const BACKEND_API_KEY = process.env.REACT_APP_BACKEND_API_KEY;
export const FORMS_API_KEY = process.env.REACT_APP_FORMS_API_KEY;
export const FORMS_API_SECRET = process.env.REACT_APP_FORMS_API_SECRET;
export const FORMS_ID = process.env.REACT_APP_FORMS_ID;
export const FORMS_ID_DE = process.env.REACT_APP_FORMS_ID_DE;

export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

export const HR_CATEGORY_ID_EN = process.env.REACT_APP_HR_CATEGORY_ID_EN;
export const HR_CATEGORY_ID_DE = process.env.REACT_APP_HR_CATEGORY_ID_DE;
export const CORONA_CATEGORY_ID_EN =
    process.env.REACT_APP_CORONA_CATEGORY_ID_EN;
export const CORONA_CATEGORY_ID_DE =
    process.env.REACT_APP_CORONA_CATEGORY_ID_DE;

export const API_ROOT = `${BACKEND_URL}/api`;
export const CORE_API = `${API_ROOT}/wp/v2`;
export const NAV_API = `${API_ROOT}/wp-api-menus/v2`;
export const META_API = `${API_ROOT}/meta/v1`;
export const FORMS_API = `${API_ROOT}/gf/v2/forms`;
export const SMS_API = `${API_ROOT}/pon-sms/v1`;
export const LANGUAGE_API = `${API_ROOT}/pon-user/v1`;
export const PREVIEW_API = `${API_ROOT}/pon-revisions/v1`;
export const TEAM_API = `${API_ROOT}/kalkhoff-team/v1`;
export const INTRANET_API = 'https://derbycycle.ponintranet.com/wp-json/wp/v2';

export const EVENTS_TYPE = 'events';
export const EVENTS_POST_TYPE = 'callidus-events';
export const DEFAULT_POST_COUNT = 12;
export const INITIAL_POST_COUNT = 6;
export const RELATED_POST_COUNT = 3;
export const DEBOUNCE_DELAY = 250;
export const HR_POST_COUNT = 6;
export const ACCIDENT_REGISTRATION_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSeidvVM1NOpVcymTUICgFwiAna-yyjR5NR4fYzloaF-E9-sAQ/viewform';
