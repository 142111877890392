import { fetchJSONFromAPI } from './api';
import { CORE_API } from '../Constants';

const API_ENDPOINT = '/categories';

/**
 * Fetch all categories
 *
 * @returns {Promise<import('../Kalkhoff').Category[]>} resolves to posts.
 */
export async function fetchCategories() {
  return fetchJSONFromAPI(CORE_API, API_ENDPOINT, {
    'per_page': '50',
    '_fields': [
      'id', 'count', 'name', 'slug', 'highlight',
    ].join(','),
  });
}
