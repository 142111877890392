/** @typedef {import('../Kalkhoff').SparsePost} SparsePost */
/** @typedef {import('../Kalkhoff').Post} Post */
import { useEffect, useState } from 'react';
import { fetchIntranetPostBySlug, fetchIntranetPosts } from '../dao/posts';

/**
 * Basic hook to fetch posts from the server.
 *
 * @returns {Array<SparsePost[]>} returns the `posts` state.
 */
export default function useIntranetPosts() {
  const [ intranetPosts, setIntranetPosts ] = useState([]);

  useEffect(() => {
    fetchIntranetPosts()
        .then((posts) => posts.map((post) => {
          return { ...post, type: 'intranet' };
        }))
        .then((posts) => setIntranetPosts(posts))
    ;
  }, []);

  return [ intranetPosts ];
}

/**
 * @param {string} slug Unique slug for the post
 * @returns {Post | SparsePost}
 *          Returns a `Post` as a state-controlled value
 */
export function useIntranetPost(slug) {
  const [ intranetPost, setIntranetPost ] = useState(null);

  useEffect(() => {
    fetchIntranetPostBySlug(slug)
        .then((post) => setIntranetPost(post || null))
    ;
  }, [ slug ]);

  return intranetPost;
}
