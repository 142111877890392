import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import createLogger from '../helpers/Logger';
import { locales } from '../i18n';
import { localizeURL } from './Link';

const log = createLogger('LanguageDetector');

/**
 * @param {string} locale Locale as a string (ll-RR)
 * @returns {string} Closest matching locale known to this application
 */
function determineKnownLocale(locale) {
  const fullMatch = locales.find(
      (knownLocale) => knownLocale.toLowerCase() === locale.toLowerCase(),
  );
  if (fullMatch) {
    log('Language was matched perfectly', locale, locales, fullMatch);
    return fullMatch;
  }
  const languageMatch = locales.find(
      (knownLocale) => {
        const lcKnown = knownLocale.toLowerCase();
        const lcLocale = locale.toLowerCase();
        return lcKnown.startsWith(lcLocale) || lcLocale.startsWith(lcKnown);
      },
  );
  if (languageMatch) {
    log('Language was matched aproximately', locale, locales, languageMatch);
    return languageMatch;
  }

  log('Could not detect locale', locale, locales, fullMatch, languageMatch);
  return locales[0];
}

/**
 * @returns {JSX.Element} ...
 */
export default function LanguageDetector() {
  const { i18n } = useTranslation();
  if (!i18n) {
    return null;
  }
  const validLocale = determineKnownLocale(i18n.language);
  i18n.changeLanguage(validLocale);
  const localizedURL = localizeURL(validLocale, window.location.pathname);
  return <Redirect to={ localizedURL } />
  ;
}
