import { fetchTotalPages } from './api';
import { CORE_API } from '../Constants';

/**
 * Fetch total amount of pages
 *
 * @param {string} endpoint - Endpoint to fetch
 * @param {Object<string, any>} [parameters] Parameters to add to the request
 *
 * @returns {Promise<number>} resolves totalpages.
 */
export function getTotalPages(
    endpoint,
    parameters,
) {
  return fetchTotalPages(
      CORE_API,
      endpoint,
      filterEmptyValuesFromObject(parameters),
  );
}

/**
 * Filter all falsy values from a object ( "", 0, false, null, undefined )
 *
 * @param {Object} object Object to filter on
 *
 * @returns {Object} Filtered object without the empty values
 */
export function filterEmptyValuesFromObject(object) {
  return Object.entries(object).reduce(
      (a, [ k, v ]) => (v ? ((a[k] = v), a) : a),
      {},
  );
}
